<template>
<AppLayout>
    <template v-slot:appContent>

        <div class="container-fluid bg-white" id="printArea">
            <div class="row" >

                <div class="col-12 px-2">
                    <div class="text-center py-2">
                        <h3 class="text-uppercase">Invoice</h3>
                    </div>

                    <h5 class="font-weight-bold"> {{technicianDetails?.user?.fullName ?? ''}} : </h5>
                    <div class="mb-1">
                        <span style="color:#727E8C;font-weight:600;">Abn : </span>
                        <span style="color:#727E8C;">{{technicianCommissionPayment?.technician?.employee?.abn ?? ''}}</span>
                    </div>
                </div>

                <div class="col-12 ">

                    <div class="" style="border:1px solid #DFE3E7;border-radius:5px">
                        <div class="p-2">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="mb-1">
                                        <span style="color:#727E8C;font-weight:500;">Bank Name : </span>
                                        <span style="color:#727E8C;"> {{technicianCommissionPayment?.technician?.user?.bankAccount?.bank_name ?? ''}}</span>
                                    </div>
                                    <div class="mb-1">
                                        <span style="color:#727E8C;font-weight:500;">Account Name : </span>
                                        <span style="color:#727E8C;"> {{technicianCommissionPayment?.technician?.user?.bankAccount?.account_name ?? ''}}</span>
                                    </div>
                                    <div class="mb-1">
                                        <span style="color:#727E8C;font-weight:500;">Account Number : </span>
                                        <span style="color:#727E8C;"> {{technicianCommissionPayment?.technician?.user?.bankAccount?.account_number ?? ''}}</span>
                                    </div>
                                    <div class="mb-1">
                                        <span style="color:#727E8C;font-weight:500;">Account BSB : </span>
                                        <span style="color:#727E8C;"> {{technicianCommissionPayment?.technician?.user?.bankAccount?.bsb ?? ''}}</span>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                  
                                        <div class="mb-1">
                                            <span style="color:#727E8C;font-weight:500;">Invoice No : </span>
                                            <span style="color:#727E8C;"> {{technicianCommissionPayment?.reference ?? ''}}</span>
                                        </div>

                                        <div class="mb-1">
                                            <span style="color:#727E8C;font-weight:500;">Generate Date : </span>
                                            <span style="color:#727E8C;">{{technicianCommissionPayment?.created_at ? dateFormatter(technicianCommissionPayment.created_at) : '' ?? ''}}</span>
                                        </div>
                                        <div class="mb-1">
                                            <span style="color:#727E8C;font-weight:500;">Time Period : </span>
                                            <span style="color:#727E8C;">{{technicianCommissionPayment?.from_date ?? ''}} - {{ technicianCommissionPayment?.to_date ?? '' }}</span>
                                        </div>
                                        <div class="mb-1">
                                            <span style="color:#727E8C;font-weight:500;">Hourly Rate : </span>
                                            <span style="color:#727E8C;">{{technicianCommissionPayment?.technician?.employee?.salary_amount ?? ''}}</span>
                                        </div>
                                   
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

                <div class="col-12 px-2">
                    <h5 class="text-center text-uppercase py-2 font-weight-bold"> INVOICE ISSUED BY TECH</h5>
                    <div>
                        <h6 class="text-bold-500"> Invoice To</h6>
                        <h6 class="text-bold-500">{{settingDefaultCompanyName?.value ?? ''}}</h6>
                        <h6 class="text-bold-500"> ABN : {{settingDefaultCompanyABN?.value ?? ''}}</h6>

                    </div>
                </div>

                <!-- <div class="col-12 pt-2 ">

                    <div class="" style="border:1px solid #DFE3E7;border-radius:5px">
                        <div class="p-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-1">
                                        <span style="color:#727E8C;font-weight:500;">Bank Name : </span>
                                        <span style="color:#727E8C;"> {{technicianCommissionPayment?.technician?.user?.bankAccount?.bank_name ?? ''}}</span>
                                    </div>
                                    <div class="mb-1">
                                        <span style="color:#727E8C;font-weight:500;">Account Name : </span>
                                        <span style="color:#727E8C;"> {{technicianCommissionPayment?.technician?.user?.bankAccount?.account_name ?? ''}}</span>
                                    </div>
                                    <div class="mb-1">
                                        <span style="color:#727E8C;font-weight:500;">Account Number : </span>
                                        <span style="color:#727E8C;"> {{technicianCommissionPayment?.technician?.user?.bankAccount?.account_number ?? ''}}</span>
                                    </div>
                                    <div class="mb-1">
                                        <span style="color:#727E8C;font-weight:500;">Account BSB : </span>
                                        <span style="color:#727E8C;"> {{technicianCommissionPayment?.technician?.user?.bankAccount?.bsb ?? ''}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">

                                </div>
                            </div>
                        </div>
                    </div>

                </div> -->

                <div class="col-12 pt-2">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Reference</th>
                                <th scope="col">Date</th>
                                <th scope="col">Customer</th>
                                <th scope="col">Duration</th>
                                <th scope="col">Prouduct Comission</th>
                                <th scope="col">Service Comission</th>
                                <th scope="col">Job Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(payment,index) in technicianAppointmentListPayments" :key="index">
                                <td>{{payment?.reference ?? '-'}}</td>
                                <td>{{payment?.date ?? '-'}}</td>
                                <td>{{payment?.customer?.userFullName ?? '-'}}</td>
                                <td>{{payment?.length ?? '-'}}</td>
                                <td>{{payment?.orderProductCommissionAmount ?  (payment.orderProductCommissionAmount/100).toFixed(2) : '-'}} </td>
                                <td>{{payment?.technicianWageAmountFromAppointment ?  (payment.technicianWageAmountFromAppointment / 100).toFixed(2)  : '-'}}</td>
                                <td>{{payment?.totalJobValue ? (payment.totalJobValue/100).toFixed(2)  : '-'}}</td>

                            </tr>

                        </tbody>
                    </table>

                </div>
                <div class="col-12">
                    <div class="pt-2">
                        <table class="table table-borderless">
                            <tbody>
                                <tr class="mb-1">
                                    <td class="py-0 pl-0 pr-0 text-right" style="width: 90%; "> Total Extra Commission Payments Addition Amount :</td>
                                    <td class="py-0 pl-2 pr-0" style="width: 100%;"><span class="font-weight-light" style="">$ {{(totalExtraCommissionPaymentsAdditionAmount/100).toFixed(2)}}</span></td>
                                </tr>
                                <tr class="mb-1">
                                    <td class="py-0 pl-0 pr-0 text-right" style="width: 90%; ">Total Extra Commission Payments Deduction Amount :</td>
                                    <td class="py-0 pl-2 pr-0" style="width: 100%;"><span class="font-weight-light" style="">$ {{(totalExtraCommissionPaymentsDeductionAmount/100).toFixed(2)}}</span></td>
                                </tr>
                                <tr class="mb-1">
                                    <td class="py-0 pl-0 pr-0 text-right" style="width: 90%; ">Total Extra Commission Payments Fuel Allowance :</td>
                                    <td class="py-0 pl-2 pr-0" style="width: 100%;"><span class="font-weight-light" style="">$ {{(totalExtraCommissionPaymentsFuelAllowance/100).toFixed(2)}}</span></td>
                                </tr>
                                <tr class="mb-1">
                                    <td class="py-0 pl-0 pr-0 text-right" style="width: 90%; ">Total Order Product Commission Amount :</td>
                                    <td class="py-0 pl-2 pr-0" style="width: 100%;"><span class="font-weight-light" style="">$ {{(totalOrderProductCommissionAmount/100).toFixed(2)}}</span></td>
                                </tr>
                                <tr class="mb-1">
                                    <td class="py-0 pl-0 pr-0 text-right" style="width: 90%; ">Total Service Hour :</td>
                                    <td class="py-0 pl-2 pr-0" style="width: 100%;"><span class="font-weight-light" style="">{{totalServiceHour}}</span></td>
                                </tr>
                                <tr class="mb-1">
                                    <td class="py-0 pl-0 pr-0 text-right" style="width: 90%; ">Total Technician Commission Payable Amount :</td>
                                    <td class="py-0 pl-2 pr-0" style="width: 100%;"><span class="font-weight-light" style="">$ {{(totalTechnicianCommissionPayableAmount/100).toFixed(2)}}</span></td>
                                </tr>
                                <tr class="mb-1">
                                    <td class="py-0 pl-0 pr-0 text-right" style="width: 90%; ">Total Technician Wage Amount From Appointment :</td>
                                    <td class="py-0 pl-2 pr-0" style="width: 100%;"><span class="font-weight-light" style="">$ {{(totalTechnicianWageAmountFromAppointment/100).toFixed(2)}}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div class="text-center py-2">
                <button class="btn btn-primary" @click="print()">print</button>
            </div>
        </div>
    </template>

</AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout'

import {
    mapActions,
    mapGetters
} from "vuex";

import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";
import PrintElement from "@/components/backEnd/mixins/PrintElement";

export default {
    name: "TechnicianCommissionPaymentPrint",
    components: {
        AppLayout,
        
    },
    mixins: [Loader, ShowToastMessage, Authorization,PrintElement],
    data() {
        return {
            getTechnicianCommissionPaymentParams: {
                with_relation: [
                    'technician.employee',
                    'technician.user.bankAccount',
                ],
            },
            getTechnicianExtraCommissionsParams: {
                tech_com_pay_id: null,
                technician_id: null,
            },

            technicianDetails: null,
            technicianAppointmentListPayments: [],
            totalServiceHour: null,
            totalTechnicianWageAmountFromAppointment: 0,
            totalOrderProductCommissionAmount: 0,
            totalExtraCommissionPaymentsAdditionAmount: 0,
            totalExtraCommissionPaymentsFuelAllowance: 0,
            totalExtraCommissionPaymentsDeductionAmount: 0,
            totalTechnicianCommissionPayableAmount: 0,
            getSettingParams: {
                type: ['default'],
                key: [
                    'default_company_abn',
                ],
            },
        };
    },
    computed: {
        ...mapGetters({
            technicianCommissionPayment: "appTechnicianCommissionPayments/technicianCommissionPayment",
            technicianExtraCommissionPayments: "appTechnicianExtraCommissions/technicianExtraCommissions",
            settingDefaultCompanyName: "appSettings/settingDefaultCompanyName",
            settingDefaultCompanyABN: "appSettings/settingDefaultCompanyABN",
        })
    },
    watch: {},
    methods: {
        ...mapActions({
            getSettings: 'appSettings/getSettings',

            commitNotFoundRouteStatus: 'commitNotFoundRouteStatus',

            getTechnicianCommissionPayment: 'appTechnicianCommissionPayments/getTechnicianCommissionPayment',
            resetTechnicianCommissionPayment: 'appTechnicianCommissionPayments/resetTechnicianCommissionPayment',

            getTechnicianExtraCommissions: 'appTechnicianExtraCommissions/getTechnicianExtraCommissions',
            restTechnicianExtraCommissions: 'appTechnicianExtraCommissions/resetTechnicianExtraCommissions',

            postTechnicianExtraCommissionOnList: 'appTechnicianExtraCommissions/postTechnicianExtraCommissionOnList',
            deleteTechnicianExtraCommissionOnList: 'appTechnicianExtraCommissions/deleteTechnicianExtraCommissionOnList'
        }),

        dateFormatter(dateString) {
            // create a new Date object using the input date string
            const date = new Date(dateString);

            // create an array of month names
            const monthNames = [
                "January", "February", "March", "April", "May", "June", "July",
                "August", "September", "October", "November", "December"
            ];

            // get the day, month, and year from the Date object
            const day = date.getDate();
            const monthIndex = date.getMonth();
            const year = date.getFullYear();

            // add a leading zero to the day if it's a single digit
            const formattedDay = day < 10 ? `0${day}` : day;

            // format the date string as "dd Month yyyy" and return
            return `${formattedDay} ${monthNames[monthIndex]} ${year}`;
        },

        convertToTime(decimal) {
            let hours = Math.floor(decimal);
            let minutes = Math.round((decimal - hours) * 60);
            return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':00';
        },

        async getSingleTechnicianCommissionPayment(id) {
            let paramObj = {
                id: id,
                params: this.getTechnicianCommissionPaymentParams,
            }
            await this.getTechnicianCommissionPayment(paramObj).then(async response => {
                if (response.status === 404) {
                    await this.commitNotFoundRouteStatus(true);
                    return;
                }

                if (response.status !== 200) {
                    await this.showToastMessage(response);
                    return;
                }
                let technicianCommissionPaymentDetails = JSON.parse(this.technicianCommissionPayment ?.details);

                this.technicianDetails = technicianCommissionPaymentDetails ?.technicianDetails;
                this.technicianAppointmentListPayments = technicianCommissionPaymentDetails ?.technicianAppointmentListPayments;

                this.totalTechnicianWageAmountFromAppointment = technicianCommissionPaymentDetails ?.totalTechnicianWageAmountFromAppointment;
                this.totalOrderProductCommissionAmount = technicianCommissionPaymentDetails ?.totalOrderProductCommissionAmount;
                this.totalServiceHour = this.convertToTime(technicianCommissionPaymentDetails ?.totalServiceHour);

            })
        },

        async getTechnicianExtraCommissionList() {
            this.getTechnicianExtraCommissionsParams.tech_com_pay_id = this.technicianCommissionPayment ?.id;
            this.getTechnicianExtraCommissionsParams.technician_id = this.technicianCommissionPayment ?.technician ?.id;

            await this.getTechnicianExtraCommissions(this.getTechnicianExtraCommissionsParams).then(async response => {
                if (response.status !== 200) {
                    this.showToastMessage(response);
                }
            });
        },

        async calculateTechnicianExtraCommissionRelatedDataCalculation() {
            this.totalExtraCommissionPaymentsAdditionAmount = await this.totalExtraCommissionPaymentsAdditionAmountCalculation() ?? 0;
            this.totalExtraCommissionPaymentsFuelAllowance = await this.totalExtraCommissionPaymentsTravelAllowanceAmountCalculation() ?? 0;
            this.totalExtraCommissionPaymentsDeductionAmount = await this.totalExtraCommissionPaymentsDeductionAmountCalculation() ?? 0;
            this.totalTechnicianCommissionPayableAmount = await this.totalPayableCommissionCalculation() ?? 0;
        },

        async totalExtraCommissionPaymentsAdditionAmountCalculation() {
            return this.technicianExtraCommissionPayments ?.reduce((accumulator, currentValue) => {
                if (currentValue ?.commission_type === 'Addition' || currentValue ?.commission_type === 0) {
                    accumulator += currentValue ?.amount ?? 0;
                }
                return accumulator;
            }, 0);
        },

        async totalExtraCommissionPaymentsDeductionAmountCalculation() {
            return this.technicianExtraCommissionPayments ?.reduce((accumulator, currentValue) => {
                if (currentValue ?.commission_type === 'Deduction' || currentValue ?.commission_type === 1) {
                    accumulator += currentValue ?.amount ?? 0;
                }
                return accumulator;
            }, 0);
        },

        async totalExtraCommissionPaymentsTravelAllowanceAmountCalculation() {
            return this.technicianExtraCommissionPayments ?.reduce((accumulator, currentValue) => {
                if (currentValue ?.commission_type === 'Travel Allowance' || currentValue ?.commission_type === 2) {
                    accumulator += currentValue ?.amount ?? 0;
                }
                return accumulator;
            }, 0);
        },

        async totalPayableCommissionCalculation() {
            return this.totalTechnicianWageAmountFromAppointment +
                this.totalOrderProductCommissionAmount +
                this.totalExtraCommissionPaymentsAdditionAmount +
                this.totalExtraCommissionPaymentsFuelAllowance -
                this.totalExtraCommissionPaymentsDeductionAmount;
        },
        async getSettingList(settingParams) {
            await this.getSettings(settingParams);
        },
        print() {
            let idElement = document.getElementById('printArea');
            this.printElement(idElement);
        },

    },
    async mounted() {
        await this.loader(true);
        const routeParams = await this.$route.params;
        await this.getSingleTechnicianCommissionPayment(routeParams.id);
        await this.getTechnicianExtraCommissionList();
        await this.calculateTechnicianExtraCommissionRelatedDataCalculation();
        await this.getSettingList(this.getSettingParams);
        await this.loader(false);
    },
    async beforeMount() {
        await this.resetTechnicianCommissionPayment();
        await this.restTechnicianExtraCommissions();
    }
}
</script>

<style scoped>

</style>
